import { useStores } from '../stores/store';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { config } from "../config";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { getErrorMsgFromCode } from '../utils/utils';
import Popup from 'reactjs-popup';
import Loader from './common/loader';

const emailValidation = email => {
	return (
		email.indexOf('@') !== -1 &&
		email.trim().length >= 3 &&
		email.substring(email.indexOf('@'), email.length).indexOf('.') !== -1
	);
};

function LoginPage(props) {
	const { t, i18n } = useTranslation();
	const { auth } = useStores();
	const [email, setEmail] = useState('');
	const [language, setLanguage] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState<string>("");
	const [type, setType] = useState('password');
	const [isTermsAndConditionsShown, setIsTermsAndConditionsShown] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const afterLoginUrl = useRef<string>("");
	// eslint-disable-next-line
	let navigate = useNavigate();

	useEffect(() => {		
		let lang = localStorage.getItem('language');
		setPermanentLanguage(lang ?? "en");
		auth.resetLocalInfo();
	}, [])

	const submit = async evt => {
		evt.preventDefault();

		setIsLoading(true);

		if (!emailValidation(email)) {
			setError("E-mail non valida");
			setIsLoading(false);
			return;
		} else if (password === '') {
			setError("Password non valida");
			setIsLoading(false);
			return;
		}

		const res = await auth.login(email, password);
		if (!res || res.error || !res.success) {
			setIsLoading(false);
			setError(getErrorMsgFromCode(res.code, res.msg));
		} else {
			if (auth.profile.type === 1) {
				navigate('/admin/schools');
			} else if (auth.profile.type === 2 && config.isApp()) {
				navigate('/media-sync');
			} else if (auth.profile.type === 2 && !config.isApp()) {
				setIsTermsAndConditionsShown(true);
				afterLoginUrl.current = '/user/country-selection';
			} else if (auth.profile.type === 3) {
				setIsTermsAndConditionsShown(true);
				afterLoginUrl.current = '/school/dashboard';
			} else if (auth.profile.type === 4) {
				navigate('/user/country-selection');
			} else if (auth.profile.type === 5) {
				navigate('/partner/schools');
			}
		}
	};

	const onChangeEmail = evt => {
		setEmail(evt.target.value);
	};
	
	const onChangePassword = evt => {
		setPassword(evt.target.value);
	};
	
	const onRegister = evt => {
		navigate(`/signup`);
	};

	const onPasswordRecovery = evt => {
		navigate(`/password-recovery`);
	};

	const setPermanentLanguage = lang => {
		localStorage.setItem('language', lang);
		setLanguage(lang);
		i18n.changeLanguage(lang)
	}

	const handleToggle = () => {
		if (type === 'password'){
		   setType('text')
		} else {
		   setType('password')
		}
	}

	const goNext = () => {
		navigate(afterLoginUrl.current);
	}

	return (
		<div className={`grid w-screen h-screen place-items-center bg-contain bg-no-repeat ${config.isMiri() ? 'logo-miri' : config.isMiriPro() ? 'logo-miri-pro' : 'logo-pinguin'}`}>
			<Popup open={isTermsAndConditionsShown}
				position="right center"
				closeOnDocumentClick
			>
				<div className="flex flex-col items-start p-6">
					<span>{t('termsAndConditionsText')}</span>
					<a className="border-2 border-primary p-2 rounded-xl" href={t('termsAndConditionsLink')}>{t('readMore')}</a>
					<br />
					<span>{t('privacyPolicyText')}</span>
					<a className="border-2 border-primary p-2 rounded-xl" href={t('privacyPolicyLink')}>{t('readMore')}</a>
					<br />
					<span>{t('cookiePolicyText')}</span>
					<a className="border-2 border-primary p-2 rounded-xl" href={t('cookiePolicyLink')}>{t('readMore')}</a>
					<br />
					<div className="flex flex-row justify-end p-6 gap-3">
						<button className="bg-primary p-2 rounded-xl text-white" onClick={() => goNext()}>{t('iAccept')}</button>
						<button className='border-2 border-primary rounded-xl p-2' onClick={() => setIsTermsAndConditionsShown(false)}>{t('iDontAccept')}</button>
					</div>
				</div>
			</Popup>

			<div className="w-screen h-screen flex flex-col items-center justify-center">
				<form method='post' onSubmit={submit} className="flex flex-col items-center">
					<div className="border-2 border-primary rounded-lg p-2 shadow-2xl">
						<input type="email" value={email} onChange={onChangeEmail} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" placeholder="Email" />
						<div className="form-group relative flex items-center">
							<input type={type} value={password} onChange={onChangePassword} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" placeholder="Password" />
							{ 
								type == "password" ?
									<FaEye className="absolute right-2 top-3 justify-around items-center z-10 cursor-pointer" onClick={handleToggle} />
								:
									<FaEyeSlash className="absolute right-2 top-3 justify-around items-center z-10 cursor-pointer" onClick={handleToggle} />
							}
						</div>
					</div>
					{ error != null && error.length > 0 &&
						(
							<span className="mt-2 text-primary">{error}</span>
						)
					}
					{ isLoading &&
						(
							<div className='mt-2'><Loader /></div>
						)
					}
					<button type="submit" className="bg-primary text-white text-center rounded-lg p-2 mt-4 uppercase">
						{t('login')}
					</button>
				</form>
				<button onClick={onRegister} className="border-2 border-primary text-primary text-center rounded-lg p-2 mt-2 uppercase">
					{t('register')}
				</button>
				<button onClick={onPasswordRecovery} className="text-primary text-center rounded-lg p-2 mt-2">
					{t('forgotPassword')}
				</button>
				<div className='flex flex-row items-center gap-3'>
					<div className={`w-16 cursor-pointer ${language != 'it' ? "grayscale" : ""}`} onClick={() => setPermanentLanguage('it')}><img src="/images/it_flag.png" /></div>
					<div className={`w-16 cursor-pointer ${language != 'en' ? "grayscale" : ""}`} onClick={() => setPermanentLanguage('en')}><img src="/images/en_flag.png" /></div>
					<div className={`w-16 cursor-pointer ${language != 'de' ? "grayscale" : ""}`} onClick={() => setPermanentLanguage('de')}><img src="/images/de_flag.png" /></div>
				</div>
				<div className="bg-primary w-full flex flex-col items-center sticky top-[100vh] p-2">
					<span>CampuStore Srl Società benefit - sede legale in Via Villaggio Europa 3, 36061 Bassano del Grappa (VI). REA VI 229294, P.IVA 02409740244, Capitale Sociale € 50.000 i.v. © 2025</span>
					<div className='flex flex-row space-x-2'>
						<a href="https://go.campustore.it/miri-legal">Area legale</a>
						<span>|</span>
						<a href="https://go.campustore.it/miri-privacy">Privacy</a>
						<span>|</span>
						<a href="https://go.campustore.it/miri-cookies">Cookies</a>
						<span>|</span>
						<a href="https://go.campustore.it/miri-sitemap">Sitemap</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LoginPage;