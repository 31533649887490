import React, { useEffect, useRef, useState } from 'react'
import { useStores } from '../../../stores/store'
import { Table } from '../../common/table';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FaEye } from 'react-icons/fa6';
import Loader from '../../common/loader';

function PartnerSchoolsPage() {
  const { auth } = useStores();
  const { t } = useTranslation();

  const searchKey = useRef<string>("");
  const searchRef = useRef(null);

  const [isDataError, setIsDataError] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    getSchools();
  }, [])

  const getSchools = () => {
    setIsDataLoading(true);
    setIsDataError(false);

    auth.getSchools(searchKey.current, null).then((res) => {
      setSchools(res.data);
      setIsDataLoading(false);
    }).catch((error) => {
      setIsDataLoading(false);
      setIsDataError(true);
    });
  }
    
  const columns = React.useMemo(
      () => [
      {
          Header: t('schools'),
          columns: [
          {
            Header: t('view'),
            accessor: "id",
            Cell: ({ cell }) => (
              <Link to={
                  `/partner/schools/${cell.row.values.id}`}>
                <FaEye />
              </Link>
            )
          },
          {
              Header: t('name'),
              accessor: 'name',
          },
          {
            Header: t('mechanographicCode'),
            accessor: 'code',
          },
          {
            Header: t('users'),
            accessor: "users",
            Cell: props => {
              return props.value.length;
            }
          },
          {
            Header: `${t('newsletter')}?`,
            accessor: "newsletter_consent",
            Cell: props => {
              return props.newsletter_consent ? t('yes') : t('no')
            }
          },
        ],
      },
      ],
      []
  );

  const search = event => {
    if (event.target.value && event.target.value.length < 4)
      return;

    searchKey.current = event.target.value;
    getSchools();
  }

  return (
    <div className="flex flex-col gap">
      <div className="flex flex-row justify-start">
        <span className="text-4xl">{t('schools')}</span>
      </div>
      <div className='flex flex-row mt-4 justify-between'>
        <div className='flex flex-row space-x-2'>
          <input onChange={search} ref={searchRef} placeholder={t('searchMin4Chars')} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        </div>
      </div>
      {isDataLoading && <div className='grid h-screen place-items-center'><Loader /></div> }

      {isDataError && <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div> }

      {!isDataError && !isDataLoading && <Table columns={columns} data={schools} /> }
    </div>
  )
}

export default PartnerSchoolsPage;
