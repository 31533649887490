import { useStores } from '../../stores/store';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaHome } from 'react-icons/fa';
import { config } from "../../config";
import { useEffect, useRef, useState } from 'react';
import Loader from '../common/loader';

function LessonGroupSelectionPage(props) {
	const { auth } = useStores();

	let navigate = useNavigate();
	let educationLevel = auth.getLocalEducationLevel();
    const touchEnabled = useRef<boolean>(false);
	const [lessonGroupsData, setLessonGroupsData] = useState(null);

	const refresh = () => {
		auth.getLessonGroupsFiltered(educationLevel?.fk_country, educationLevel?.id, auth.profile?.type == 4 ? true : false, config.isMiri(), config.isMiriPro()).then((res) => {
			setLessonGroupsData(res.data);
		}).catch((error) => {
		}).finally(() => {
		});
	}

	useEffect(() => {
		refresh();

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)
	}, [])

	const goToLessonSelection = lessonGroup => {
		if (!touchEnabled.current)
			return;

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)

		touchEnabled.current = false;

		auth.saveLocalLessonGroup(lessonGroup);
		navigate(`/user/lesson-selection`);
	}

	const back = () => {
		let educationLevel = auth.popLocalEducationLevelHistory();

		auth.saveLocalEducationLevel(educationLevel);

		navigate('/user/education-level-selection');
	}

	if (lessonGroupsData == null)
		return <div className='grid h-screen place-items-center'><Loader /></div>

	if (lessonGroupsData.length == 0) {
		return (
			<div className="grid h-screen place-items-center">
				<img className="w-1/2" src="/images/tappo.png" />
				<div onMouseDown={() => navigate('/user/country-selection')} onTouchStart={() => config.isMiriOrPro() ? navigate('/user/country-selection') : null} className='bg-primary p-8 rounded-lg text-white text-4xl text-center cursor-pointer'>
					<FaHome />
				</div>
			</div>
		);
	}

	return (
		<div className="grid h-screen place-items-center w-full">
			<video className="absolute -z-10 w-full h-full object-fill" autoPlay loop>
				{ config.isMiri() &&
					<source src="/video/background_miri.mp4" type="video/mp4" />
				}
				{ config.isPinguin() &&
					<source src="/video/background_pinguin.mp4" type="video/mp4" />
				}
				{ config.isMiriPro() &&
					<source src="/video/background_miripro.mp4" type="video/mp4" />
				}
			</video>

			<div className="flex flex-col items-center justify-center">
				<div className="bg-primary rounded-t-2xl p-2 text-white text-3xl w-3/4 text-center">{ educationLevel?.name }</div>
				<div className="bg-white border-2 border-primary rounded-lg p-2 shadow-2xl">
					<form className="flex items-center">
						<div className="grid grid-cols-2 gap-8 m-8">
							{ lessonGroupsData.map((lessonGroup) => (
							<div className={`flex flex-row rounded-lg p-8 w-full whitespace-nowrap mb-1 cursor-pointer`} style={{backgroundColor: educationLevel?.color}} onMouseDown={() => goToLessonSelection(lessonGroup)} onTouchStart={() => config.isMiriOrPro() ? goToLessonSelection(lessonGroup) : null} key={lessonGroup.id}>
									<div className="flex flex-col">
										<span className="w-full whitespace-nowrap mb-1 text-white text-4xl">{lessonGroup.name}</span>
										<span className="w-full whitespace-nowrap mb-1 text-white text-2xl">{educationLevel?.name}</span>
									</div>
								</div>
							))}
						</div>
					</form>
				</div>
				<div className="flex justify-start items-center gap-1 mt-4 w-full ml-8">
					<div onMouseDown={back} onTouchStart={() => config.isMiriOrPro() ? back() : null} className='bg-primary p-8 rounded-lg text-white text-4xl text-center cursor-pointer'>
						<FaArrowLeft />
					</div>
					<div onMouseDown={() => navigate('/user/country-selection')} onTouchStart={() => config.isMiriOrPro() ? navigate('/user/country-selection') : null} className='bg-primary p-8 rounded-lg text-white text-4xl text-center cursor-pointer'>
						<FaHome />
					</div>
				</div>
			</div>
		</div>
	)
}

export default LessonGroupSelectionPage;
