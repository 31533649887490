import { useStores } from '../stores/store';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loader from './common/loader';

function StartupPage(props) {
	const { auth } = useStores();
	// eslint-disable-next-line
	const [searchParams, setSearchParams] = useSearchParams();
	let navigate = useNavigate();

	useEffect(() => {
		auth.setKey(searchParams.get("key"));
		navigate('/login');
	}, [])

	return <div className='grid h-screen place-items-center'><Loader /></div>
}

export default StartupPage;