import { useStores } from '../stores/store';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';
import Loader from './common/loader';

function ProfessorSignupPage(props) {
	const { t } = useTranslation();
	const { auth } = useStores();
	const [error, setError] = useState<string>("");
	const [passwordType, setPasswordType] = useState('password');
	const [confirmPasswordType, setConfirmPasswordType] = useState('password');
	const { register: register, handleSubmit: handleSubmit, setValue: setValue, formState: formState } = useForm();
	const currentCode = useRef<string>(null);
	const [isDataError, setIsDataError] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
	// eslint-disable-next-line
	const [searchParams, setSearchParams] = useSearchParams();
	let navigate = useNavigate();


	useEffect(() => {
		currentCode.current = searchParams.get('code');

		checkRegCode(currentCode.current)
	}, [])

    const checkRegCode = (code: string) => {
        setIsDataLoading(true);
        setIsDataError(false);

        auth.checkRegCode(code).then((res) => {
			setIsDataError(false);
			setIsDataLoading(false);
        }).catch((error) => {
			setIsDataLoading(false);
			setIsDataError(true);
        });
    }

	const onSubmit = async data => {
		setError("");

		if (data.password !== data.confirmPassword) {
			setError(t('passwordsDoNotMatch'));
			return;
		}

		if (data.password.length < 8) {
			setError(t('passwordAtLeast8Chars'));
			return;
		}

		const res = await auth.professorSignup(data.name, data.surname, data.email, data.password, currentCode.current);

		if (res == null) {
			setError(t('generalError'));
		} else if (!res || res.error) {
			setError(`${t('registrationFailed')}: ${res.msg}`);
		} else {
			if (res.success)
				navigate('/');
			else
				setError(`${t('registrationFailed')}: ${res.msg}`)
		}
	};

	const handleTogglePassword = () => {
		if (passwordType === 'password'){
			setPasswordType('text')
		} else {
			setPasswordType('password')
		}
	}

	const handleToggleConfirmPassword = () => {
		if (confirmPasswordType === 'password'){
			setConfirmPasswordType('text')
		} else {
			setConfirmPasswordType('password')
		}
	}

	const back = () => {
		navigate('/')
	}

	if (isDataLoading)
        return <div className='grid h-screen place-items-center'><Loader /></div>
    
    if (isDataError) {
        return <>
			<div className='grid h-screen place-items-center text-black'>
				<div className='flex flex-col'>
					{t('linkExpiredOrInvalid')}
					<button className="bg-primary text-white text-center rounded-lg p-2 mt-4 uppercase" onClick={back}>
						{t('back')}
					</button>
				</div>
			</div>
		</>;
	}

	return (
		<div className="grid h-screen place-items-center">
			<div>
				<span className="text-primary mt-4">{t('professorRegistration')}</span>
				<div className="w-full flex flex-col items-center justify-center">
					<img alt="" src="userIcon.png" />
					<form className="border-2 border-primary p-2 rounded-2xl flex flex-col items-center justify-center" onSubmit={handleSubmit(onSubmit)}>
						<div className='flex flex-col'>
							<label className="mt-4">{t('name')} *:</label>
							<input {...register("name", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
							{formState.errors.name && <span className='text-red'>{t('fieldMandatory')}</span>}
						</div>

						<div className='flex flex-col'>
							<label className="mt-4">{t('surname')} *:</label>
							<input {...register("surname", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
							{formState.errors.name && <span className='text-red'>{t('fieldMandatory')}</span>}
						</div>

						<div className='flex flex-col'>
							<label className="mt-4">{t('email')} *:</label>
							<input {...register("email", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
							{formState.errors.email && <span className='text-red'>{t('fieldMandatory')}</span>}
						</div>

						<div className='flex flex-col'>
							<label className="mt-4">{t('password')} *:</label>
							<div className="form-group relative flex items-center">
								<input type={passwordType} {...register("password", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" placeholder="Password" />
								{ 
									passwordType == "password" ?
										<FaEye className="absolute right-2 top-3 justify-around items-center z-10 cursor-pointer" onClick={handleTogglePassword} />
									:
										<FaEyeSlash className="absolute right-2 top-3 justify-around items-center z-10 cursor-pointer" onClick={handleTogglePassword} />
								}
							</div>
						</div>
						{formState.errors.password && <span className='text-red'>{t('fieldMandatory')}</span>}

						<div className='flex flex-col'>
							<label className="mt-4">{t('confirmPassword')} *:</label>
							<div className="form-group relative flex items-center">
								<input type={confirmPasswordType} {...register("confirmPassword", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" placeholder="Password" />
								{
									confirmPasswordType == "password" ?
										<FaEye className="absolute right-2 top-3 justify-around items-center z-10 cursor-pointer" onClick={handleToggleConfirmPassword} />
									:
										<FaEyeSlash className="absolute right-2 top-3 justify-around items-center z-10 cursor-pointer" onClick={handleToggleConfirmPassword} />
								}
							</div>
						</div>

						{ error && error.length > 0 &&
							(
								<span className="text-primary">{error}</span>
							)
						}

						<button type="submit" className="bg-primary text-white text-center rounded-lg p-2 mt-4 uppercase">
							{t('register')}
						</button>
					</form>
				</div>
			</div>
		</div>
	)
}

export default ProfessorSignupPage;