import React, { useEffect, useState } from 'react'
import { useStores } from '../../../stores/store'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FaEdit, FaThumbsDown, FaThumbsUp, FaTrash } from 'react-icons/fa';
import { getElementById } from '../../common/functions';
import { Table } from '../../common/table';

import Popup from 'reactjs-popup';
import { FaCheck, FaX } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import Loader from '../../common/loader';

function AdminLessonGroupsPage() {
  const { t } = useTranslation();

  const { auth } = useStores();
  const [searchParams, setSearchParams] = useSearchParams();
  const [referralId, setReferralId] = useState("");
  const [confirmId, setConfirmId] = useState<number>(null);
  const [countries, setCountries] = useState([]);
  const [isFilterError, setIsFilterError] = useState(false);
  const [isFilterLoading, setIsFilterLoading] = useState(true);
  const [isDataError, setIsDataError] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [lessonGroups, setLessonGroups] = useState([]);
  const [currentCountry, setCurrentCountry] = useState(null);
  let navigate = useNavigate();

  useEffect(() => {
    setReferralId(searchParams.get('referral_id'));

    setIsFilterLoading(true);
    setIsFilterError(false);

    auth.getCountries().then((res) => {
      setCountries(res.data);
      if (res.data.length > 0) {
        let filterCountry = localStorage.getItem('lessonGroups_countryKey');
        if (filterCountry !== undefined && filterCountry !== null) {
          setCurrentCountry(getElementById(res.data, filterCountry));
        } else {
          setCurrentCountry(res.data[0]);
        }
      }
      setIsFilterLoading(false);
    }).catch((error) => {
      setIsFilterLoading(false);
      setIsFilterError(true);
    });
  }, [])

  useEffect(() => {
    getGroups();
  }, [currentCountry])

  const getGroups = () => {
    if (currentCountry == null)
      return;

    setIsDataLoading(true);
    setIsDataError(false);

    auth.getLessonGroups(currentCountry.id).then((res) => {
      setLessonGroups(prevState => res.data.map((elm) => {return {...elm, is_selected: false}}));
      setIsDataLoading(false);
    }).catch((error) => {
      setIsDataLoading(false);
      setIsDataError(true);
    });
  }

  const addLessonGroups = async () => {
    await auth.addLessonGroupsToEducationLevel(currentCountry?.id, referralId, lessonGroups.filter((elm) => elm.is_selected == true));
    navigate(`/admin/education-levels/?parent_id=${referralId}&returned_from_select=1`);
  }

  const onDelete = (id) => {
    setConfirmId(id);
  }

  const doDelete = async () => {
    await auth.deleteLessonGroup(confirmId);
    setConfirmId(null);
    getGroups();
  }

  const handleCountryChange = ({ target }) => {
    localStorage.setItem('lessonGroups_countryKey', target.value);

    let country = getElementById(countries, target.value);
    setCurrentCountry(country);
  }

  const select = (id) => {
    setLessonGroups(lessonGroups.map(item => item.id === id ? {...item, is_selected: true} : item))
  }

  const deselect = (id) => {
    setLessonGroups(lessonGroups.map(item => item.id === id ? {...item, is_selected: false} : item))
  }

  const normalColumns = React.useMemo(
      () => [
      {
          Header: t('modules'),
          columns: [
          {
            Header: t('modify'),
            accessor: "id",
            Cell: ({ cell }) => (
              <Link to={
                  `/admin/lesson-groups/${cell.row.values.id}`}>
                <FaEdit />
              </Link>
            )
          },
          {
            Header: t('internalName'),
            accessor: 'description',
          },
          {
              Header: t('name'),
              accessor: 'name',
          },

          {
            Header: t('priority'),
            accessor: 'priority',
          },
          {
            Header: t('lessons'),
            accessor: "lessons",
            Cell: ({ cell }) => (
              <Link className="underline" to={`/admin/lesson-groups/associate/${cell.row.values.id}/`}>
                {cell.row.values.lessons?.length}
              </Link>
            )
          },
          {
            Header: t('completed'),
            accessor: 'is_completed',
            Cell: ({ cell }) => (
              <div>
                { cell.row.values.is_completed && <FaThumbsUp className='text-green'/> }
                { !cell.row.values.is_completed && <FaThumbsDown className='text-red'/> }
              </div>
            )
          },
          {
            Header: 'Demo?',
            accessor: 'is_demo_enabled',
            Cell: ({ cell }) => (
              <div>
                { cell.row.values.is_demo_enabled && <FaThumbsUp className='text-green'/> }
                { !cell.row.values.is_demo_enabled && <FaThumbsDown className='text-red'/> }
              </div>
            )
          },
          {
            Header: t('delete'),
            accessor: "ida",
            Cell: ({ cell }) => (
              <FaTrash onClick={() => onDelete(cell.row.values.id)} className='cursor-pointer' />
            )
          },
          ],
      },
      ],
      []
  );

  const selectColumns = [
    {
        Header: t('modules'),
        columns: [
        {
          Header: t('select'),
          accessor: "is_selected",
          Cell: ({ cell }) => (
            <div>
              { cell.row.values.is_selected && <FaCheck onClick={ () => deselect(cell.row.original.id) } className='cursor-pointer' /> }
              { !cell.row.values.is_selected && <FaX onClick={ () => select(cell.row.original.id) } className='cursor-pointer' /> }
            </div>
          )
        },
        {
            Header: t('name'),
            accessor: 'name',
        },
        {
            Header: t('description'),
            accessor: 'description',
        },
        {
          Header: t('level'),
          accessor: 'level_str',
        },
        {
          Header: t('completed'),
          accessor: 'is_completed',
          Cell: ({ cell }) => (
            <div>
              { cell.row.values.is_completed && <FaThumbsUp className='text-green'/> }
              { !cell.row.values.is_completed && <FaThumbsDown className='text-red'/> }
            </div>
          )
        },
        ],
    },
    ];

  if (isFilterLoading)
    return <div className='grid h-screen place-items-center'><Loader /></div>

  if (isFilterError)
    return <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div>

  return (
    <div>
      
      <Popup open={confirmId !== null}
        position="right center"
        closeOnDocumentClick
      >
        <div className="flex flex-col items-center gap-6 p-6">
          <span>{t('confirmDelete')}</span>
          <div className="flex flex-row items-center gap-6">
              <button onClick={() => doDelete()} className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">{t('yes')}</button>
              <button onClick={() => setConfirmId(null)} className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">{t('no')}</button>
          </div>
        </div>
      </Popup>

      <div className="flex flex-col gap-6">
        <div className="flex flex-row justify-start">
          <span className="text-4xl">{t('lessons')}</span>
        </div>
        <div className="flex flex-row justify-between">
          <div className="flex flex-col">
            <select onChange={handleCountryChange} value={currentCountry?.id} className="bg-primary text-white rounded-full p-4 cursor-pointer">
              {countries?.map(({ id, name }, index) => <option value={id} >{name}</option>)}
            </select>
          </div>
          {referralId == undefined && <Link className="bg-primary hover:bg-blue-700 text-white font-bold p-4 rounded-full" to="/admin/lesson-groups/new">{t('addLesson')}</Link> }
          {referralId != undefined && <button className="bg-primary hover:bg-blue-700 text-white font-bold p-4 rounded-full" onClick={addLessonGroups}>{t('addLessonToLevel')}</button> }
        </div>

        {isDataLoading && <div className='grid h-screen place-items-center'><Loader /></div> }

        {isDataError && <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div> }

        {referralId === null && !isDataError && !isDataLoading && <Table columns={normalColumns} data={lessonGroups} /> }

        {referralId !== undefined && referralId !== null && !isDataError && !isDataLoading && <Table columns={selectColumns} data={lessonGroups} /> }
      </div>
    </div>
  )
}

export default AdminLessonGroupsPage;
