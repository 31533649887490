import { Trans, useTranslation } from 'react-i18next';
import { useStores } from '../../stores/store';
import { useEffect, useState } from 'react';
import { config } from "../../config";
import Moment from 'moment';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';
import Loader from '../common/loader';

function SchoolDashboardPage(props) {
	const { auth } = useStores();
    const { t, i18n } = useTranslation();

    const [isDataError, setIsDataError] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isChangePasswordShown, setIsChangePasswordShown] = useState(false);

    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");

    const [passwordType, setPasswordType] = useState('password');
    const [confirmPasswordType, setConfirmPasswordType] = useState('password');

    const [error, setError] = useState<string>("")
    const [success, setSuccess] = useState<boolean>(false)

    const [self, setSelf] = useState(null);
    const [regUrl, setRegUrl] = useState("");
    const [validity, setValidity] = useState("");

    useEffect(() => {
        getRegCode();
        getSchool();
    }, [])

    const getSchool = () => {
      auth.getSelf().then((res) => {
        console.log(res);
        setSelf(res.data);
      }).catch((error) => {
        setSelf(null);
      });
    }

    const doChangePassword = async () => {
      if (isChangePasswordShown) {
        if (password.length == 0 || confirmPassword.length == 0) {
            setError(t('passwordNotValid'));
            return;
        }
        if (password != confirmPassword) {
            setError(t('passwordNotValid'));
            return;
        }
      }

      auth.editPassword(self.id, password).then((res) => {
        setSuccess(true);
        setError("");
        setPassword("");
        setConfirmPassword("");
      }).catch((error) => {
        setError(t('passwordChangedNotSuccessfully'))
      });
    }

    const getRegCode = () => {
        setIsDataLoading(true);
        setIsDataError(false);

        auth.getRegCode().then((res) => {
          if (config.isMiri()) {
            setRegUrl(`https://app.aulamiri.it/professor-signup/?code=${res.data.regcode}`);
          } else if (config.isMiriPro()) {
            setRegUrl(`https://app.miripro.it/professor-signup/?code=${res.data.regcode}`);
          } else {
            setRegUrl(`https://app.tavolopinguin.it/professor-signup/?code=${res.data.regcode}`);
          }
          setValidity(res.data.validity)
          setIsDataLoading(false);
        }).catch((error) => {
          setRegUrl("");
          setIsDataLoading(false);
          setIsDataError(true);
        });
    }

    const invalidate = () => {
        setIsDataLoading(true);
        setIsDataError(false);

        auth.delRegCode().then((res) => {
          if (config.isMiri()) {
            setRegUrl(`https://app.aulamiri.it/professor-signup/?code=${res.data.regcode}`);
          } else if (config.isMiriPro()) {
            setRegUrl(`https://app.miripro.it/professor-signup/?code=${res.data.regcode}`);
          } else {
            setRegUrl(`https://app.tavolopinguin.it/professor-signup/?code=${res.data.regcode}`);
          }
          setValidity(res.data.validity)
          setIsDataLoading(false);
        }).catch((error) => {
          setRegUrl("");
          setIsDataLoading(false);
          setIsDataError(true);
        });
    }

    const handleTogglePassword = () => {
      if (passwordType === 'password'){
        setPasswordType('text')
      } else {
        setPasswordType('password')
      }
    }
  
      const handleToggleConfirmPassword = () => {
      if (confirmPasswordType === 'password'){
        setConfirmPasswordType('text')
      } else {
        setConfirmPasswordType('password')
      }
    }

    if (isDataLoading)
        return <div className='grid h-screen place-items-center'><Loader /></div>
    
    if (isDataError)
        return <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div>

	return (
    <div className='w-full p-12 flex flex-col justify-start'>
      <div className="w-full flex flex-row items-start justify-start">
        <div className="w-1/2 flex flex-col justify-start">
          <div className='border-2 border-primary rounded-xl p-4'>
            {
              config.isMiriOrPro() ?
                <Trans i18nKey="welcomeSchoolMiri" components={{ br: <br />, link: <a href="https://www.giuntiscuola.it/formazione/modulo/nuovo-aggiornamento-miri-e-dashboard-la-scuola-cosa-cambia">qui</a> }} />
              :
                <Trans i18nKey="welcomeSchoolPinguin" components={{ br: <br />, link: <a href="https://www.giuntiscuola.it/formazione/modulo/aggiornamento-dellapp-pinguin-la-nuova-dashboard-e-il-composer">qui</a> }} />
            }
          </div>

        </div>
        <div className="w-1/2 flex flex-col p-12 justify-start">
            <span className="font-bold">E-email: { self?.email }</span>
            <button className="mt-2 border-2 border-primary hover:bg-blue-700 text-primary font-bold py-2 px-4 rounded-full" onClick={(e) => { e.preventDefault(); setError(""); setSuccess(false); setIsChangePasswordShown(!isChangePasswordShown)}}>{t('modifyPassword')}</button>
            { isChangePasswordShown &&

              <div className='mt-4'>
                <div className="flex flex-col">
                  <label className="block text-gray-700 text-sm font-bold mb-2">{t('password')}</label>
                  <div className="form-group relative flex items-center">
                    <input type={passwordType} onChange={e => setPassword(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Password" />
                      {
                        passwordType == "password" ?
                          <FaEye className="absolute right-2 top-3 justify-around items-center z-10 cursor-pointer" onClick={handleTogglePassword} />
                        :
                          <FaEyeSlash className="absolute right-2 top-3 justify-around items-center z-10 cursor-pointer" onClick={handleTogglePassword} />
                      }
                  </div>
                </div>
            
                <div className="flex flex-col mt-2">
                  <label className="block text-gray-700 text-sm font-bold mb-2">{t('confirmPassword')}</label>
                  <div className="form-group relative flex items-center">
                    <input type={confirmPasswordType} onChange={(e) => setConfirmPassword(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Password" />
							        {
								        confirmPasswordType == "password" ?
									        <FaEye className="absolute right-2 top-3 justify-around items-center z-10 cursor-pointer" onClick={handleToggleConfirmPassword} />
								        :
									        <FaEyeSlash className="absolute right-2 top-3 justify-around items-center z-10 cursor-pointer" onClick={handleToggleConfirmPassword} />
							        }
						      </div>
                </div>
            
                <div className='mt-2'>
                  { error.length > 0 && <span className="text-red">{error}</span> }
                  { success && <span className="text-green">{t('passwordChangedSuccessfully')}</span> }
                </div>
            
                <button className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mt-2" onClick={() => doChangePassword()}>{t('save')}</button>
              </div>
            }
        </div>
      </div>
      <span className="text-primary mt-4">{t('professorRegistration')}</span>
      <div className='flex flex-row justify-between items-center border-2 border-primary rounded-xl p-4'>
        <div className='flex flex-col'>
          <span className='mr-4'>Link: {regUrl}</span>
          <span>{t('codeIsValidUntil')} {Moment(validity).format('DD/MM/yyyy HH:mm')}</span>
        </div>
        <div>
          <button onClick={getRegCode} className="border-2 border-primary text-primary text-center rounded-lg p-2 mt-2 mr-4">
            {t('refreshLink')}
          </button>

          <button onClick={invalidate} className="border-2 border-primary text-primary text-center rounded-lg p-2 mt-2">
            {t('generateNewLink')}
          </button>
        </div>
      </div>
    </div>
	)
}

export default SchoolDashboardPage;
