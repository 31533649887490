import { useForm } from "react-hook-form";
import { Link, useNavigate } from 'react-router-dom';
import { useStores } from "../../../stores/store";
import { useEffect, useRef, useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { Table } from "../../common/table";
import Popup from "reactjs-popup";
import { useTranslation } from "react-i18next";
import Loader from "../../common/loader";

export default function PartnerCodeGenerationPage() {
    const { t } = useTranslation();

    const { auth } = useStores();
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    let navigate = useNavigate();

    const [step, setStep] = useState(1);

    const [isDataError, setIsDataError] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [formData, setFormData] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const [educationLevels, setEducationLevels] = useState([]);
    const [lessonGroups, setLessonGroups] = useState([]);
    const [currentCountry, setCurrentCountry] = useState(null);
    const [currentEducationLevel, setCurrentEducationLevel] = useState(null);
    const [currentEducationLevelHistory, setCurrentEducationLevelHistory] = useState([]);
    const savedEducationLevelHistory = useRef([]);

    const savedSelectedCountries = useRef([]);
    const savedSelectedEducationLevels = useRef([]);
    const savedSelectedLessonGroups = useRef([]);

    useEffect(() => {
        setIsDataLoading(true);
        setIsDataError(false);

        auth.getSelfCountry().then((res) => {
            setCurrentCountry(res.data);
            setIsDataLoading(false);
        }).catch((error) => {
            setIsDataLoading(false);
            setIsDataError(true);
        });


        if (step > 1) {
            auth.getEducationLevels(currentCountry?.id, currentEducationLevel?.id).then((res) => {
                setEducationLevels(res.data);

                if (res.data.length == 0) {
                    auth.getEducationLevel(currentCountry?.id, currentEducationLevel?.id).then((res) => {
                        setLessonGroups(res.data.lesson_groups);
                        setIsDataLoading(false);
                      }).catch((error) => {
                        setIsDataLoading(false);
                        setIsDataError(false);
                      });
                } else {
                    setIsDataLoading(false);
                }
            }).catch((error) => {
                setIsDataLoading(false);
                setIsDataError(true);
            });
        }
    }, [step, currentEducationLevel])

    const countriesColumns = [{
        Header: " ",
        columns: [
        {
            Header: t('select'),
            accessor: "id",
            Cell: ({ cell }) => (
                <input type="checkbox" className="border border-primary rounded-xl p-2" onChange={(e) => handleCountryChange(e.target.checked, cell.row.values)}></input>
            )
        },
        {
            Header: t('name'),
            accessor: 'name',
        },
        {
            Header: t('enter'),
            Cell: ({ cell }) => (
                <button onClick={() => {
                    setCurrentCountry(cell.row.values);
                    setStep(3);
                }}>
                <FaArrowRight />
                </button>
            )
            },
        ],
    }];

    const educationLevelColumns = [{
        Header: t('educationLevel'),
        columns: [
        {
            Header: t('select'),
            accessor: "id",
            Cell: ({ cell }) => (
                <input type="checkbox" className="border border-primary rounded-xl p-2" onChange={(e) => handleEducationLevelChange(e.target.checked, cell.row.values)}></input>
            )
        },
        {
            Header: t('name'),
            accessor: 'name',
        },
        {
            Header: t('description'),
            accessor: 'description',
        },
        {
            Header: t('enter'),
            Cell: ({ cell }) => (
            <button onClick={() => {
                savedEducationLevelHistory.current.push(cell.row.values)
                setCurrentEducationLevel(cell.row.values);
                setCurrentEducationLevelHistory([...currentEducationLevelHistory, currentEducationLevel]);
            }}>
                <FaArrowRight />
            </button>
            )
        },
    ]}];

    const lessonGroupColumns = [{
        Header: t('lessons'),
        columns: [
        {
            Header: t('select'),
            accessor: "id",
            Cell: ({ cell }) => (
                <input type="checkbox" className="border border-primary rounded-xl p-2" onChange={(e) => handleLessonGroupChange(e.target.checked, cell.row.values)}></input>
            )
        },
        {
            Header: t('name'),
            accessor: 'name',
        },
    ]}];

    const handleCountryChange = (value, obj) => {
        if (value) {
            savedSelectedCountries.current.push(obj);
        } else {
            savedSelectedCountries.current = savedSelectedCountries.current.filter((objList) => obj.id !== objList.id);
        }
    }

    const handleEducationLevelChange = (value, obj) => {
        if (value) {
            savedSelectedEducationLevels.current.push(obj);
        } else {
            savedSelectedEducationLevels.current = savedSelectedEducationLevels.current.filter((objList) => obj.id !== objList.id);
        }
    }

    const handleLessonGroupChange = (value, obj) => {
        if (value) {
            savedSelectedLessonGroups.current.push(obj);
        } else {
            savedSelectedLessonGroups.current = savedSelectedLessonGroups.current.filter((objList) => obj.id !== objList.id);
        }
    }

    const removeDupsAndClean = (arr) => {
        return arr.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t != null && t['id'] === value['id']
            ))
        )
    }

    const save = async () => {
        let tempData = formData;

        let cleanEls = [...savedEducationLevelHistory.current, currentEducationLevel]
        cleanEls = removeDupsAndClean(cleanEls);

        let licenseData = {};

        if (savedSelectedLessonGroups.current.length > 0) {
            licenseData["countries"] = [currentCountry];
            licenseData["educationLevels"] = cleanEls;
            licenseData["lessonGroups"] = removeDupsAndClean(savedSelectedLessonGroups.current);
        }

        if (savedSelectedEducationLevels.current.length > 0) {
            licenseData["countries"] = [currentCountry];
            licenseData["educationLevels"] = savedSelectedEducationLevels.current;
            licenseData["lessonGroups"] = [];
        }

        if (savedSelectedCountries.current.length > 0) {
            licenseData["countries"] = savedSelectedCountries.current;
            licenseData["educationLevels"] = [];
            licenseData["lessonGroups"] = [];
        }

        tempData['license_data'] = licenseData;


        await auth.addCodes(tempData);
        navigate(`/partner/codes`);
    }

    const nextStep = (data) => {
        setFormData(data);
        setStep(step + 1);
    }

    const prevStep = () => {
        setLessonGroups([]);
        setEducationLevels([]);

        if (currentEducationLevelHistory.length == 0) {
            setStep(step - 1);
        } else {
            savedEducationLevelHistory.current.pop();
            let elm = currentEducationLevelHistory.pop();
            setCurrentEducationLevel(elm);
        }
    }

    if (isDataLoading)
        return <div className='grid h-screen place-items-center'><Loader /></div>

    if (isDataError)
        return <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div>

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row justify-center">
                <span className="text-4xl">{t('codeGenerationStep')} {step}/3</span>
            </div>
            <div className="w-full flex flex-row justify-center">
                <Popup open={showConfirm}
                    position="right center"
                    closeOnDocumentClick
                >
                    <div className="flex flex-col items-center gap-6 p-6">
                        <span>{t('confirmCodes')}</span>
                        <div className="flex flex-row items-center gap-6">
                            <button onClick={save} className="bg-primary text-white font-bold py-2 px-4 rounded-full">{t('yes')}</button>
                            <button onClick={(e) => { e.preventDefault(); setShowConfirm(false) }} className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full">{t('no')}</button>
                        </div>
                    </div>
                </Popup>
                <div className="w-1/2 bg-white rounded-xl">
                    { step == 1 &&
                        <form onSubmit={handleSubmit(nextStep)} className="w-full flex flex-col items-between gap-6 p-4">
                            <div className="w-full flex flex-col items-center gap-6 p-4">
                                <div className="flex flex-col">
                                    <label className="block text-gray-700 text-sm font-bold mb-2">{t('howManyCodes')}</label>
                                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("count", { required: true })} />
                                    {errors.countRequired && <span>{t('fieldMandatory')}</span>}
                                </div>
                                <div className="flex flex-col">
                                    <label className="block text-gray-700 text-sm font-bold mb-2">{t('type')}</label>
                                    <select {...register("type", { required: true })} className="bg-primary text-white rounded-full p-2 cursor-pointer">
                                        <option value="1">Pinguin</option>
                                        <option value="2">Miri</option>
                                        <option value="3">Miri PRO</option>
                                    </select>
                                    {errors.typeRequired && <span>{t('fieldMandatory')}</span>}
                                </div>
                                <div className="flex flex-col">
                                    <label className="block text-gray-700 text-sm font-bold mb-2">{t('validity')}</label>
                                    <select {...register("validity_year", { required: true })} className="bg-primary text-white rounded-full p-2 cursor-pointer">
                                        <option value="1">1 {t('year')}</option>
                                        <option value="2">2 {t('years')}</option>
                                        <option value="3">3 {t('years')}</option>
                                        <option value="4">4 {t('years')}</option>
                                        <option value="5">5 {t('years')}</option>
                                        <option value="1000">Lifetime</option>
                                    </select>
                                    {errors.validityRequired && <span>{t('fieldMandatory')}</span>}
                                </div>
                                <div className="flex flex-col">
                                    <label className="block text-gray-700 text-sm font-bold mb-2">Lotto</label>
                                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("lot")} />
                                </div>
                            </div>
                            <div className="flex flex-row justify-between">
                                <Link to={'/partner/codes'} className="bg-primary text-white font-bold p-4 rounded-full">{t('cancel')}</Link>
                                <button className="bg-primary text-white font-bold py-2 px-4 rounded-full" type="submit">{t('next')}</button>
                            </div>
                        </form>
                    }
                    { step == 2 && educationLevels != null && educationLevels.length > 0 &&
                        <div className="w-full flex flex-col items-between gap-6 p-4">
                            <Table columns={educationLevelColumns} data={educationLevels} /> 
                            <div className="flex flex-row justify-between">
                                <button onClick={prevStep} className="bg-primary text-white font-bold p-4 rounded-full">{t('prev')}</button>
                                <button onClick={() => setShowConfirm(true)} className="bg-primary text-white font-bold p-4 rounded-full">{t('generateCodes')}</button>
                            </div>
                        </div>
                    }
                    { step == 2 && lessonGroups != null && lessonGroups.length > 0 &&
                        <div className="w-full flex flex-col items-between gap-6 p-4">
                            <Table columns={lessonGroupColumns} data={lessonGroups} /> 
                            <div className="flex flex-row justify-between">
                                <button onClick={prevStep} className="bg-primary text-white font-bold p-4 rounded-full">{t('prev')}</button>
                                <button onClick={() => setShowConfirm(true)} className="bg-primary text-white font-bold p-4 rounded-full">{t('generateCodes')}</button>
                            </div>
                        </div>
                    }
                    { step == 2 && ((lessonGroups == null || lessonGroups.length == 0) && (educationLevels == null || educationLevels.length == 0)) &&
                        <div className="w-full flex flex-col justify-center items-center gap-6 p-4">
                            {t('noData')}
                            <div className="flex flex-row justify-between">
                                <button onClick={prevStep} className="bg-primary text-white font-bold p-4 rounded-full">{t('back')}</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
