import { Link } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../config';

export function PartnerHeader() {
    const { t } = useTranslation();

    return (
        <nav className="bg-gray-800">
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-16 items-center justify-between">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                        <button type="button" className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                            <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                        <div className="flex flex-shrink-0 items-center">
                            { config.isMiriPro() &&
                                <img className="hidden h-8 w-auto lg:block" src="/images/logo_miripro.jpg" alt="Miri PRO" />
                            }
                            { !config.isMiriPro() &&
                                <img className="hidden h-8 w-auto lg:block" src="/images/logo_miri.jpg" alt="Aula Miri" />
                            }                        </div>
                        <div className="hidden sm:ml-6 sm:block">
                            <div className="flex space-x-4">
                                <div className="p-10">
                                    <ul className="hidden md:flex space-x-6">
                                        <li className="flex relative group">
                                            <a href="#" className="mr-1">{t('users')}</a>
                                            <i className="fa-solid fa-chevron-down fa-2xs pt-3"></i>
                                            <ul className="absolute bg-white p-3 w-52 top-6 transform scale-0 group-hover:scale-100 transition duration-150 ease-in-out origin-top shadow-lg">
                                                <li className="text-sm hover:bg-slate-100 leading-8"><Link to="/partner/schools">{t('schools')}</Link></li>
                                                <li className="text-sm hover:bg-slate-100 leading-8"><Link to="/partner/users">{t('users')}</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="/partner/codes">{t('codes')}</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

                        <div className="relative ml-3">
                            <div className='flex flex-row items-center gap-4'>
                                <span className='border-2 border-black rounded-3xl p-1'>{t('partner')}</span>
                                <Link to="/login"><FaSignOutAlt /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}
