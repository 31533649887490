import { useStores } from '../../stores/store';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaHome } from 'react-icons/fa';
import { config } from "../../config";
import { useEffect, useRef, useState } from 'react';
import Loader from '../common/loader';

function LessonSelectionPage(props) {
	const { auth } = useStores();
	let navigate = useNavigate();
	let school = auth.getLocalSchool();
	let educationLevel = auth.getLocalEducationLevel();
	let lessonGroup = auth.getLocalLessonGroup();
//	let lessons = auth.getLocalLessonGroup().lessons.sort((a, b) => (a.name < b.name) ? -1 : 1);
	const touchEnabled = useRef<boolean>(false);
	const [lessonsData, setLessonsData] = useState([]);

	const refresh = () => {
		auth.getLessonsOfGroup(lessonGroup.id, school.license).then((res) => {
			setLessonsData(res.data);
		}).catch((error) => {
		}).finally(() => {
		});
	}

	useEffect(() => {
		refresh();

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)
	}, [])

	const goToLesson = lesson => {
		if (!touchEnabled.current)
			return;

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)

		touchEnabled.current = false;

		auth.saveLocalLesson(lesson);

		navigate('/user/interactive-lesson');
	}

	if (lessonsData == null)
		return <div className='grid h-screen place-items-center'><Loader /></div>

	if (lessonsData.length == 0) {
		return <div className='grid h-screen place-items-center'>
			<div>
				Nessuna lezione presente
				<div onMouseDown={() => navigate(`/user/lesson-group-selection`)} onTouchStart={() => config.isMiriOrPro() ? navigate(`/user/lesson-group-selection`) : null} className='bg-primary p-8 rounded-lg text-white text-4xl text-center cursor-pointer'>
					BACK
				</div>
			</div>
		</div>
	}

	return (
		<div className="grid h-screen place-items-center w-full">
			<video className="absolute -z-10 w-full h-full object-fill" autoPlay loop>
				{ config.isMiri() &&
					<source src="/video/background_miri.mp4" type="video/mp4" />
				}
				{ config.isPinguin() &&
					<source src="/video/background_pinguin.mp4" type="video/mp4" />
				}
				{ config.isMiriPro() &&
					<source src="/video/background_miripro.mp4" type="video/mp4" />
				}
			</video>
			<div className="flex flex-col items-center justify-center">
				<div style={{ backgroundColor: educationLevel?.color }} className="rounded-t-3xl p-2 text-white text-3xl w-3/4 text-center">{ lessonGroup?.name }</div>
				<div style={{ borderColor: educationLevel?.color }} className="bg-white border-2 rounded-lg py-8 px-6 shadow-2xl">
					<form className="flex items-center">
						<div className="grid grid-rows-3 grid-flow-col p-1 gap-x-10 cursor-pointer">
							{ lessonsData.map((lesson, index) => (
								<div onMouseDown={() => goToLesson(lesson)} onTouchStart={() => config.isMiriOrPro() ? goToLesson(lesson) : null} key={lesson.id} className="rounded-lg p-1 w-full whitespace-nowrap mb-6 flex flex-row justify-start items-center">
									<span style={{ backgroundColor: educationLevel?.color }} className="text-white text-4xl rounded-md px-3 pt-2 text-center mr-2">{index+1}</span>
									<div className='flex flex-col'>
										<span className='text-4xl'>{lesson.title}</span>
										<span className='text-2xl'>{lesson.description}</span>
									</div>
								</div>
							))}
						</div>
					</form>
				</div>
				<div className="flex justify-start items-center gap-1 mt-4 w-full ml-8">
					<div onMouseDown={() => navigate(`/user/lesson-group-selection`)} onTouchStart={() => config.isMiriOrPro() ? navigate(`/user/lesson-group-selection`) : null} className='bg-primary p-8 rounded-lg text-white text-4xl text-center cursor-pointer'>
						<FaArrowLeft />
					</div>
					<div onMouseDown={() => navigate("/user/country-selection")} onTouchStart={() => config.isMiriOrPro() ? navigate("/user/country-selection") : null} className="bg-primary rounded-lg p-8 text-white text-4xl text-center cursor-pointer">
						<FaHome />
					</div>
				</div>
			</div>
		</div>
	)
}

export default LessonSelectionPage;
