import React, { useEffect, useRef, useState } from 'react'
import { useStores } from '../../../stores/store'
import { Table } from '../../common/table';
import { useTranslation } from 'react-i18next';
import Loader from '../../common/loader';

function PartnerUsersPage() {
  const { auth } = useStores();
  const { t } = useTranslation();

  const searchKey = useRef<string>("");
  const searchRef = useRef(null);

  const [currentType, setCurrentType] = useState(5);
  const [countries, setCountries] = useState([]);
  const [isDataError, setIsDataError] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setCurrentType(localStorage.getItem('partner_users_type') ? +localStorage.getItem('partner_users_type') : 5);
    searchKey.current = localStorage.getItem('partner_users_searchKey');
  }, [])

  const getUsers = () => {
    setIsDataLoading(true);
    setIsDataError(false);

    auth.getUsers(currentType, searchKey.current).then((res) => {
      setUsers(res.data);
      setIsDataLoading(false);
    }).catch((error) => {
      setIsDataLoading(false);
      setIsDataError(true);
    });
  }

  useEffect(() => {
    getUsers();
  }, [currentType])

  const handleTypeChange = ({ target }) => {
    localStorage.setItem('partner_users_type', target.value);
    setCurrentType(target.value);
  }
    
  const userColumns = React.useMemo(
    () => [
    {
        Header: t('users'),
        columns: [
          {
              Header: t('companyName'),
              accessor: 'pretty_name',
          },
          {
            Header: t('type'),
            accessor: 'type',
            Cell: ({ cell }) => (
              cell.row.values.type == 1 ? <span>{t('admin')}</span> : 
              cell.row.values.type == 2 ? <span>{t('professor')}</span> : 
              cell.row.values.type == 3 ? <span>{t('school')}</span> : 
              cell.row.values.type == 4 ? <span>{t('demo')}</span> :
              cell.row.values.type == 5 ? <span>{t('partner')}</span> : <span></span>
            )
          },
          {
              Header: t('school'),
              accessor: 'school.name',
          },
          {
            Header: t('mechanographicCode'),
            accessor: 'school.code',
          },
        ],
    },
    {
      Header: "Login",
      columns: [
      {
          Header: t('email'),
          accessor: 'email',
      },
      {
        Header: t('active'),
        accessor: "is_old",
        Cell: ({ cell }) => (
          cell.row.values.is_old ? t('no') : t('yes')
        )
      },
      ],
  },
  ],
    []
);

const schoolColumns = React.useMemo(
  () => [
  {
      Header: t('users'),
      columns: [
        {
            Header: t('school'),
            accessor: 'pretty_name',
        },
        {
          Header: t('type'),
          accessor: 'type',
          Cell: ({ cell }) => (
            cell.row.values.type == 1 ? <span>{t('admin')}</span> : 
            cell.row.values.type == 2 ? <span>{t('professor')}</span> : 
            cell.row.values.type == 3 ? <span>{t('school')}</span> : 
            cell.row.values.type == 4 ? <span>{t('demo')}</span> :
            cell.row.values.type == 5 ? <span>{t('partner')}</span> : <span></span>
          )
        },
        {
          Header: t('mechanographicCode'),
          accessor: 'school.code',
        },
      ],
  },
  {
    Header: "Login",
    columns: [
    {
        Header: t('email'),
        accessor: 'email',
    },
    {
      Header: t('active'),
      accessor: "is_old",
      Cell: ({ cell }) => (
        cell.row.values.is_old ? t('no') : t('yes')
      )
    },
    ],
},
],
  []
);

const partnerColumns = React.useMemo(
  () => [
  {
      Header: t('users'),
      columns: [
        {
            Header: t('name'),
            accessor: 'pretty_name',
        },
        {
          Header: t('folder'),
          accessor: 'fk_country_name',
        },
        {
          Header: t('type'),
          accessor: 'type',
          Cell: ({ cell }) => (
            cell.row.values.type == 1 ? <span>{t('admin')}</span> : 
            cell.row.values.type == 2 ? <span>{t('professor')}</span> : 
            cell.row.values.type == 3 ? <span>{t('school')}</span> : 
            cell.row.values.type == 4 ? <span>{t('demo')}</span> :
            cell.row.values.type == 5 ? <span>{t('partner')}</span> : <span></span>
          )
        },
      ],
  },
  {
    Header: "Login",
    columns: [
    {
        Header: t('email'),
        accessor: 'email',
    },
    {
      Header: t('active'),
      accessor: "is_old",
      Cell: ({ cell }) => (
        cell.row.values.is_old ? t('no') : t('yes')
      )
    },
    ],
  },
  ],
    []
  );

  const search = event => {
    if (event.target.value && event.target.value.length < 4)
      return;

    localStorage.setItem('partner_users_searchKey', event.target.value);

    searchKey.current = event.target.value;
    getUsers();
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-start">
        <span className="text-4xl">{ t('users') }</span>
      </div>

      <div className="flex flex-row justify-between">
        <div className="flex flex-row space-x-2">
        <select onChange={handleTypeChange} defaultValue={currentType} className="bg-primary text-white rounded-full p-4 cursor-pointer">
          <option value="5">{t('partner')}</option>
          <option value="3">{t('school')}</option>
          <option value="2">{t('professor')}</option>
        </select>

          <input onChange={search} ref={searchRef} defaultValue={searchKey.current} placeholder={t('search')} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        </div>
      </div>

      {isDataLoading && <div className='grid h-screen place-items-center'><Loader /></div> }

      {isDataError && <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div> }

      {!isDataError && !isDataLoading && currentType == 2 && <Table columns={userColumns} data={users} /> }
      {!isDataError && !isDataLoading && currentType == 5 && <Table columns={partnerColumns} data={users} /> }
      {!isDataError && !isDataLoading && currentType == 3 && <Table columns={schoolColumns} data={users} /> }
    </div>
  )
}

export default PartnerUsersPage;
